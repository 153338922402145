import React from "react";
import { SEO } from "../components/SEO";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { ServiceQuery } from "../types/graphql";

export const query = graphql`
  query serviceQuery {
    file(relativePath: { eq: "img/service.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

type Props = {
  data: ServiceQuery;
};

const Services = ({ data }: Props): React.ReactElement => {
  return (
    <>
      <SEO title="Meine Dienste" metaDescription="Meine Dienste" />

      <div className="flex flex-col m-3">
        <div>
          Ob Edelrost, Verzinkt, Pulver-beschichtet, in Farbe oder ganz Bunt
          lässt sich für Jedermann etwas Schönes für die Gartengestaltung
          finden. Ich stehe Ihnen für sämtliche Ideen, Fragen und
          Sonderanfertigungen gern zur Seite.
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          <div className="mt-2 mb-2">
            <ul className="list-disc list-inside">
              <li>Handel mit</li>
              <ul className="list-square list-inside ml-5">
                <li>Zäunen</li>
                <li>Geländer</li>
                <li>Tore</li>
              </ul>
              <li>Dekorationen aus Metall</li>
              <li>Sonderanfertigungen aus Metall</li>
              <li>Skulpturenbau</li>
              <li>Kunst</li>
            </ul>
          </div>
          <div className="md:w-2/5">
            <Img fluid={data!.file!.childImageSharp!.fluid} alt="Kim Ohlsen" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
